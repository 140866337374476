import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { multipleAPICalls, updateUserData } from './appSlice'

const PageReload = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (localStorage.jwtToken) {
      const response = []
      dispatch(multipleAPICalls(response))
        .then((result) => {
          dispatch(updateUserData(result))
        })
        .catch((err) => {
          console.log('error')
        })
    }
  }, [dispatch])
  return null
}
export default PageReload
