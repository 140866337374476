import { createSlice } from '@reduxjs/toolkit'
import API_URL from '../api/apiEndpoint'
import AxiosInterceptor from '../api/axiosInterceptor'
// import { useSelector } from 'react-redux'

export const signInUser = (data) => (dispatch) => {
  // const stateData = useSelector((state) => state.appSlice)

  return new Promise((resolve, reject) => {
    AxiosInterceptor.post(API_URL.sign_in, data)
      .then((response) => {
        resolve(response)
        console.log(response)
        localStorage.setItem('jwtToken', response.token)
        localStorage.setItem('userId', response.user_id._id)
        localStorage.setItem('stateId', response.user_id.state_id)
        // if (!!stateData?.userData?.user?.image_url === false) {
        //   localStorage.setItem('img_Url', '')
        // } else {
        //   localStorage.setItem('img_Url', stateData?.userData?.user?.image_url)
        // }
        const settingObj = JSON.stringify(response.global_settings[0])
        localStorage.setItem('settings', settingObj)
        dispatch(multipleAPICalls(response))
          .then((result) => {
            resolve(response)

            dispatch(updateUserData(result))
          })
          .catch((err) => {
            console.log('error')
          })
      })
      .catch((err) => {
        console.log('error')
      })
  })
}
export const multipleAPICalls = (data) => async (dispatch) => {
  const parliment = await AxiosInterceptor.get(API_URL.parliment)
  const acs = await AxiosInterceptor.get(API_URL.acs)
  const state = await AxiosInterceptor.get(API_URL.state)
  const cluster2 = await AxiosInterceptor.get(API_URL.cluster2)
  const cluster1 = await AxiosInterceptor.get(API_URL.cluster1)
  const booth = await AxiosInterceptor.get(API_URL.booth)
  const user = await AxiosInterceptor.get(API_URL.get_user)
  const getroles = await AxiosInterceptor.post(API_URL.role)
  console.log('user', user)
  const Clt1Name = JSON.parse(localStorage.settings)
  console.log('Clt1Name', Clt1Name.clt1_name_e)
  const enable = []
  var adminstr
  var adminmatch
  var stacadminstr
  var stacadminmatch
  var pcadminstr
  var pcadminmatch
  var acadminstr
  var acadminmatch
  var clt1adminstr
  var clt1adminmatch
  if (
    user?.global_settings[0]?.inst_type === 'ST' ||
    user?.global_settings[0]?.inst_type === 'PC'
  ) {
    adminstr = 'ADM'
    adminmatch = user?.user_data?.user_roles?.indexOf(adminstr) > -1
    stacadminstr = 'STADM'
    stacadminmatch = user?.user_data?.user_roles.indexOf(stacadminstr) > -1
    pcadminstr = 'PCADM'
    pcadminmatch = user?.user_data?.user_roles.indexOf(pcadminstr) > -1
    acadminstr = 'ACADM'
    acadminmatch = user?.user_data?.user_roles.indexOf(acadminstr) > -1
    clt1adminstr = 'Clt1ADM'
    clt1adminmatch = user?.user_data?.user_roles.indexOf(clt1adminstr) > -1

    if (adminmatch === true || stacadminmatch === true || pcadminmatch === true) {
      enable.push({ enableAc: true })
    } else {
      enable.push({ enableAc: false })
    }
    if (adminmatch === true || stacadminmatch === true || pcadminmatch === true) {
      Object.assign(enable[0], { enableClt1: true })
    } else {
      Object.assign(enable[0], { enableClt1: false })
    }
    if (acadminmatch === true) {
      Object.assign(enable[0], { enableClt1: true, acadm: true })
    } else if (clt1adminmatch === true) {
      Object.assign(enable[0], { enableClt1: false, acadm: false, clt1adm: true })
    }
  } else {
    adminstr = 'ADM'
    adminmatch = user?.user_data?.user_roles?.indexOf(adminstr) > -1
    acadminstr = 'ACADM'
    acadminmatch = user?.user_data?.user_roles.indexOf(acadminstr) > -1
    clt1adminstr = 'Clt1ADM'
    clt1adminmatch = user?.user_data?.user_roles.indexOf(clt1adminstr) > -1
    if (adminmatch === true || acadminmatch === true) {
      enable.push({ enableAc: false, enableClt1: true, acadm: true })
    } else if (clt1adminmatch === true) {
      enable.push({ enableAc: false, enableClt1: false, acadm: false, clt1adm: true })
    }
  }
  if (
    user?.global_settings[0]?.inst_type === 'ST' ||
    user?.global_settings[0]?.inst_type === 'PC'
  ) {
    adminstr = 'ADM'
    adminmatch = user?.user_data?.user_roles?.indexOf(adminstr) > -1
    stacadminstr = 'STADM'
    stacadminmatch = user?.user_data?.user_roles.indexOf(stacadminstr) > -1
    pcadminstr = 'PCADM'
    pcadminmatch = user?.user_data?.user_roles.indexOf(pcadminstr) > -1
    acadminstr = 'ACADM'
    acadminmatch = user?.user_data?.user_roles.indexOf(acadminstr) > -1
    clt1adminstr = 'Clt1ADM'
    clt1adminmatch = user?.user_data?.user_roles.indexOf(clt1adminstr) > -1

    if (adminmatch === true || stacadminmatch === true || pcadminmatch === true) {
      enable.push({ enableAc: true })
    } else {
      enable.push({ enableAc: false })
    }
    if (adminmatch === true || stacadminmatch === true || pcadminmatch === true) {
      Object.assign(enable[0], { enableClt1: true })
    } else {
      Object.assign(enable[0], { enableClt1: false })
    }
    if (acadminmatch === true) {
      Object.assign(enable[0], { enableClt1: true, acadm: true })
    } else if (clt1adminmatch === true) {
      Object.assign(enable[0], { enableClt1: false, acadm: false, clt1adm: true })
    }
  } else {
    adminstr = 'ADM'
    adminmatch = user?.user_data?.user_roles?.indexOf(adminstr) > -1
    acadminstr = 'ACADM'
    acadminmatch = user?.user_data?.user_roles.indexOf(acadminstr) > -1
    clt1adminstr = 'Clt1ADM'
    clt1adminmatch = user?.user_data?.user_roles.indexOf(clt1adminstr) > -1
    if (adminmatch === true || acadminmatch === true) {
      enable.push({ enableAc: false, enableClt1: true, acadm: true })
    } else if (clt1adminmatch === true) {
      enable.push({ enableAc: false, enableClt1: false, acadm: false, clt1adm: true })
    }
  }
  // var clt1List = []
  // if (acadminmatch === true) {
  //   url =
  //     API_URL.list_cluster1 +
  //     `?page=${1}&size=${500}&state_id=${localStorage.getItem('stateId')}&ac_id=${user?.user_data?.user}`
  //   const searchResult = await AxiosInterceptor.get(url)
  // }
  // if (clt1adminmatch === true) {
  // }

  const api_results = [
    { pcs: parliment.pcs_Data },
    { acs: acs.acs_Data },
    { state: state.state_Data },
    { clt2Data: cluster2.clt2_data },
    { clt1Data: cluster1.clt1_data },
    { boothData: booth.booth_Data },
    { userData: enable },
    { user: user.user_data },
    { settings: user },
    { user_data: user },
    { get_roles: getroles },
    //{ roles: roles.roles },
    //{ booth: booth.booth_Data },
  ]
  if (api_results.length === 11) {
    return api_results
  }
}
const initial_state = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  userData: [],
}
const appSlice = createSlice({
  name: 'app/data',
  initialState: initial_state,
  reducers: {
    updateUserData: (state, action) => {
      return { ...state, userData: action.payload }
    },
    updateSidebarShow: (state, action) => {
      return { ...state, sidebarShow: !state.sidebarShow }
    },
  },
})
export const { updateUserData, updateSidebarShow } = appSlice.actions
export default appSlice.reducer
