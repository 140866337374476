import React from 'react'
import { useIdleTimer } from 'react-idle-timer'

import axios from 'axios'
const SESSION_IDEL_MINUTES = 20

const AutoLagoutTimer = (props) => {
  console.log(props)
  const { ComposedClass } = props

  const handleOnIdle = async (event) => {
    // SHOW YOUR MODAL HERE AND LAGOUT
    console.log('user is idle', event)
    console.log('last active', getLastActiveTime())
    try {
      console.log(localStorage.getItem('jwtToken'))
      if (localStorage.getItem('jwtToken') !== null) {
        let data = { id: localStorage.getItem('userId') }
        let result = await axios.post(process.env.REACT_APP_BASE_URL + '/logout', data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        })
        console.log('agent status response', result)
        if (result.data.status_code === 1) {
          localStorage.clear()
          window.location.reload()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  })

  return <ComposedClass />
}

export default AutoLagoutTimer
